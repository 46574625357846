import { Alert, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, Stack, TextField, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add';
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useInterval } from "./react-hooks";
import { useWebsocket } from "./useWebsocket";

export const wsBaseUrl = ():string => {
    var loc = window.location, new_uri;
    if(loc.host == "localhost"){
        // hack for local development through the CRA proxy
        return "ws://localhost:8080"
    }else{

        if (loc.protocol === "https:") {
            new_uri = "wss:";
        } else {
            new_uri = "ws:";
        }
        return "//" + loc.host;
    }
}

export const useUpdatesStream = (windowId:string)=>{
    const [isConnected, status] =  useWebsocket<TimeWindowSnapshot|undefined>(`${wsBaseUrl()}/api/time-window/${windowId}/stream`, undefined, (event, previous) => {
        return JSON.parse(event.data)
      })

    return status
}


export const toYearMonthDay = (now:dayjs.Dayjs):YearMonthDay => {
    return {
        year:now.year(),
        month:now.month(),
        day:now.date(),
    }
}

const dateKey = (d:YearMonthDay):string => `${d.year}-${d.month}-${d.day}`

/*
  Todo: 
    - create the "ready only" display page 
    - add websocket backend for data updates
    - make the admin page do the updates
    - get time from the server instead of the client, so we don't have to worry about the date on the pi
*/

interface TimeWindowEntry{
    text:string
}
interface TimeWindowData {
    entries:Record<string, TimeWindowEntry[]>
}
interface TimeWindowSnapshot {
    now:number
    data:TimeWindowData
}
const fakeData:TimeWindowData = {
    entries:{
        [dateKey(toYearMonthDay(dayjs()))]:[
            {text:"Caregiver comes at 8:00 AM"},
            {text:"Shower and Hair Washing Day"},
        ],

        [dateKey(toYearMonthDay(dayjs().add(1, "day")))]:[
            {text:"Caregiver comes at 8:00 AM"},
            {text:"Appointment with Dr. Bracket at 2pm"}
        ]
    }
}

const getData = (windowId:string):Promise<TimeWindowSnapshot>=>{
    return fetch(`/api/time-window/${windowId}`).then(r=>{
        if(r.status!=200){
            return Promise.reject(`Server said ${r.status}/${r.statusText}`)
        }else{
            return r.json()
        }
    })
} 

const postData = (windowId:string, data:TimeWindowData)=>{
    return fetch(`/api/time-window/${windowId}`, {
        method:"PUT",
        body:JSON.stringify(data)
    })
}

const DayEntriesView = (props:{label:string, day:YearMonthDay, data:TimeWindowData})=>{
    const {label, day, data} = props
    const entries = data?.entries[dateKey(day)] 
    if(!entries || entries.length==0) return <></>
    return (
        <>
        <Typography variant={"h5"} style={{textAlign:"left"}}>{label}:</Typography>
        <ul>
            {entries?.map(e=>{
                return <li><Typography variant={"h5"} style={{textAlign:"left"}}>{e.text}</Typography></li>
            })}
        </ul>
        </>
    )
}

export default (props:{})=>{
    const {windowId} = useParams()

    const snapshot = useUpdatesStream(windowId!!)

    const data = snapshot?.data


    const now = dayjs(snapshot?.now)
    const today = toYearMonthDay(now)
    const tomorrow = toYearMonthDay(now.add(1, "day"))

    return <>
    <Stack spacing={2} style={{padding:"30px", textAlign:"center"}}>
        <Typography variant={"h2"}>{now.format("dddd")}</Typography>
        <Typography variant={"h5"}>{now.hour() < 12 ? "Morning" : "Afternoon"}</Typography>
        <Typography variant={"h2"}>{now.format("hh:mm a")}</Typography>
        <Typography variant={"h5"}>{now.format("MMMM D, YYYY")}</Typography>

        {!data ? <CircularProgress/> : <>
            <DayEntriesView label="Today" day={today} data={data}/>
            <DayEntriesView label="Tomorrow" day={tomorrow} data={data}/>
        </>}
    </Stack>
    </>
}

interface YearMonthDay {
    year:number
    month:number
    day:number
}

export const TimeWindowAdmin = (props:{})=>{
    const {windowId} = useParams()
    const [snapshot, setSnapshot] = useState<TimeWindowSnapshot>()
    const [n, setN] = useState<number>()
    const [error, setError] = useState<string>()

    const data = snapshot?.data
    const now = dayjs(n)


    const today = toYearMonthDay(now)
    const tomorrow = toYearMonthDay(now.add(1, "day"))

    const name = windowId == "9d43c71c-8691-11ef-b88e-5a96de4b8531" ? "Mary Jane Smith" : "???"


    const refresh = ()=>{
        getData(windowId!!).then(s=>{
            setSnapshot(s)
            setN(s.now)
        }).catch(setError)
    }

    const handleUpdate = (data:TimeWindowData)=>{
        postData(windowId!!, data).then(refresh)
    }

    useEffect(refresh, [])
    useInterval(()=>{
        // just a hack to get the time to update regularly
        getData(windowId!!).then(s => setN(s.now))
    }, 10000)


    return <>
        <Stack spacing={2} style={{padding:"30px"}}>
            <Typography variant={"h2"}>{name}</Typography>
            <Divider/>
            {error && <Alert color="error">{error}</Alert>}
            {!data ? <CircularProgress/> : <>
                <DayEntriesAdmin 
                    label="Today"
                    day={today}
                    data={data}
                    onChange={handleUpdate}    
                />
                <Divider/>
                <DayEntriesAdmin 
                    label="Tomorrow"
                    day={tomorrow}
                    data={data}
                    onChange={handleUpdate}    
                />
            </>}
        </Stack>
    </>
}


const withEntries = (day:YearMonthDay, data:TimeWindowData, entries:TimeWindowEntry[], ):TimeWindowData => {
    return {
        ... data,
        entries:{
            ... data.entries,
            [dateKey(day)]:entries
        }
    }
}

const DayEntriesAdmin = (props:{label:string, day:YearMonthDay, data:TimeWindowData, onChange:(update:TimeWindowData)=>void}) => {
    const {label, day, data, onChange} = props

    const n = dayjs().year(day.year).month(day.month).date(day.day)
    const entries = data?.entries[dateKey(day)] 

    console.log("entries for", dateKey(day), "are", entries)

    const [showDialog, setShowDialog] = useState(false)

    return (<>
        {showDialog && <NewEntryDialog
            onClose={()=>setShowDialog(false)}
            onSave={(t)=>{
                const update:TimeWindowData = withEntries(day, data, (entries ?? []).concat([{
                    text : t
                }]))

                console.log("Update is ", update)
                onChange(update)
                setShowDialog(false)
            }}
        />}
        <Typography variant={"h5"}>{label} ({n.format('MM/DD/YYYY')})</Typography>
        {(!entries || entries.length == 0) ? `There are no entries for ${label}` : <>
            {entries.map(entry=>{
                return <Box style={{border:"1px solid grey", padding:"20px"}}>
                    <Typography>{entry.text}</Typography>
                    <Button color="error" onClick={()=>{
                        onChange(withEntries(day, data, (entries ?? []).filter(e=> entry.text != e.text)))
                    }}>delete</Button>
                </Box>
            })}
        </>}
        <Button onClick={()=>setShowDialog(true)}>Add Entry</Button>
    </>)
}

const NewEntryDialog = (props:{onSave:(text:string)=>void, onClose:()=>void})=>{
    const {onSave, onClose} = props
    const [text, setText] = useState("")
    
    const doSave = ()=>onSave(text)

    return (<>
        <Dialog open={true} fullWidth={true} onClose={onClose}>
            <DialogTitle>Add/Edit Entry</DialogTitle>
            <DialogContent>
                    <Stack spacing={2}>

                        {/* {error && <Alert color="error">{error}</Alert>} */}
                        <TextField
                            margin="dense"
                            id="Description"
                            label="Description"
                            value={text}
                            onChange={e => setText((e.target.value))}
                            fullWidth
                            multiline
                            rows={8}
                            variant="filled"
                        />
                    </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>Cancel</Button>
                <Button variant='contained' onClick={doSave}>Save</Button>
            </DialogActions>
        </Dialog>
    </>)
}