import { Accordion, AccordionDetails, AccordionSummary, Alert, AppBar, Autocomplete, Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, IconButton, MenuItem, Select, Switch, TextField, Toolbar, Typography, createFilterOptions } from "@mui/material"
import { Stack } from "@mui/system"
import React, { CSSProperties, MouseEventHandler, ReactNode, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { Aspect, AspectRef, AspectValue, Components, ItemComponent, ItemStatus, PlanConfig, PlanOverviewDto, Release, ReleaseRef, StatusChange } from "./Api"
import { ReorderableList } from "./DragAndDrop"
import linkify from "markdown-linkify"
import _ from "underscore"
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import { useViewport } from 'react-viewport-hooks';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateTimePicker } from "@mui/x-date-pickers"
import moment, { Moment } from "moment"
import LabeledSelect from "./LabeledSelect";
import { NewItemSpec, VersionListEntry, addAspectValue, applyChanges, getActualPlan, getComponents, getPlanConfig, getPlanOverview, getVersions, patchAspectAssignments, sendItemsPatch, setEstimate, setItemOrder, setReleases, setTitles, updateItemStatus } from "./api-actions";
import ItemEditorDialog from "./ItemEditorDialog";
import { PlanSubmenu } from "./PlanSubmenu";
import { findLastStatusChange } from "./item-logic";
import PlanScreenLayout, { breadcrumbStyle } from "./PlanScreenLayout";
import PlanControlsBand from "./PlanControlsBand";
import MarkdownContent from "./MarkdownContent";

export const EstimateDialog = (props: { value: number | undefined, onComplete: (value: number) => void, onCancel: () => void }) => {
    const [value, setValue] = React.useState(props.value)

    return (<Dialog open={true}>
        <DialogTitle>Set Estimate</DialogTitle>
        <DialogContent>
            <DialogContentText>
                An estimate is not a promise .. it's just your best idea of how big this is
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="Estimate"
                label="Estimate"
                type="number"
                value={value}
                onChange={e => setValue(parseInt(e.target.value))}
                fullWidth
                variant="standard"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel}>Cancel</Button>
            <Button onClick={() => value ? props.onComplete(value) : props.onCancel()}>Save</Button>
        </DialogActions>
    </Dialog>)
}

const TitleDialog = (props: { value: string | undefined, onComplete: (value: string) => void, onCancel: () => void }) => {
    const [value, setValue] = React.useState(props.value)

    return (<Dialog open={true}>
        <DialogTitle>Set Custom Title</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Name it what it is
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="Title"
                label="Title"
                value={value}
                onChange={e => setValue((e.target.value))}
                fullWidth
                variant="standard"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel}>Cancel</Button>
            <Button onClick={() => value ? props.onComplete(value) : props.onCancel()}>Save</Button>
        </DialogActions>
    </Dialog>)
}

export interface ReleaseOption {
    existing?: ReleaseRef
    inputValue?: string
    label: string
}

export const ReleaseSelect = (props:{ value: ReleaseRef | undefined, options: Release[], style?:CSSProperties, onChange:(r:ReleaseRef)=>void})=>{
    const {value, style, onChange} = props
    const options: ReleaseOption[] = props.options.map(o => ({
        existing: {releaseId:o.id},
        label: o.name
    }))
    const getOption = (id:string):ReleaseOption|undefined => options.find(o=>o.existing?.releaseId == id)
    const handleOption = (option: ReleaseOption) => {
        console.log("Option selected", option)
        if (option.existing) {
            onChange(option.existing)
        } else if (option.inputValue) {
            onChange({ releaseId: option.inputValue })
        }
    }
    return (
        <Autocomplete
            style={style}
            fullWidth
            options={options}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return getOption(option)?.label || option
                }
                return option.label;
            }}
            onChange={(e, v) => handleOption((v as any) as ReleaseOption)}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label="Release" variant="outlined" />
            )}
            value={value?.releaseId}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                console.log("params.inputValue ", params.inputValue)
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push({
                        inputValue: params.inputValue,
                        label: `${params.inputValue}`,
                    });
                }

                return filtered;
            }}
        ></Autocomplete>)
}

const filter = createFilterOptions<ReleaseOption>();
export const ReleaseDialog = (props: { value: ReleaseRef | undefined, options: Release[], onComplete: (value: ReleaseRef) => void, onCancel: () => void }) => {

    const [value, setValue] = React.useState<ReleaseRef | undefined>(props.value)

    return (<Dialog open={true} maxWidth={"sm"} fullWidth>
        <DialogTitle>Set Release for Item</DialogTitle>
        <DialogContent>
            <ReleaseSelect
                value={value}
                options={props.options}
                onChange={setValue}
                style={{ margin: "20px" }}
                />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel}>Cancel</Button>
            <Button onClick={() => value ? props.onComplete(value) : props.onCancel()}>Save</Button>
        </DialogActions>
    </Dialog>)
}

export const AspectSelect = (props:{value: AspectOption | undefined, aspect: Aspect, 
    fullWidth?: boolean, style?:CSSProperties, onSelect:(value: AspectOption)=>void})=>{
    const { value, aspect, fullWidth, style, onSelect } = props
    
    const options: AspectOption[] = aspect.values.map(o => ({
        aspect: aspect,
        existing: o,
        label: o.name
    }))

    return (
        <Autocomplete
            style={style}
            options={options}
            fullWidth={fullWidth}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {
                    return option;
                }
                return option.label;
            }}
            onChange={(e, v) => {
                onSelect((v as any) as AspectOption)
            }}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label={aspect.name} variant="outlined" />
            )}
            value={value?.existing?.name ?? value?.label}
            filterOptions={(options, params) => {
                const filtered = options;
                console.log("params.inputValue ", params.inputValue)
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                    filtered.push({
                        aspect: aspect,
                        existing: undefined,
                        label: `${params.inputValue}`,
                    });
                }

                return filtered;
            }}
        ></Autocomplete>)
}

export interface AspectOption {
    aspect: Aspect,
    existing: AspectValue | undefined
    label: string
}
export const AspectLabelDialog = (props: { value: AspectRef | undefined, aspect: Aspect, onComplete: (value: AspectOption) => void, onCancel: () => void }) => {
    const { aspect } = props

    const options: AspectOption[] = aspect.values.map(o => ({
        aspect: aspect,
        existing: o,
        label: o.name
    }))
    const [value, setValue] = React.useState<AspectOption | undefined>(options.find(o => o.existing?.id == props.value?.valueId))


    const handleOption = (option: AspectOption) => {
        console.log("Option selected", option)
        setValue(option)
    }
    return (<Dialog open={true} maxWidth={"sm"} fullWidth>
        <DialogTitle>Set "{aspect.name}" for Item</DialogTitle>
        <DialogContent>
            <Typography>
                {aspect.description}
            </Typography>
            <AspectSelect 
                aspect={aspect} 
                value={value} 
                onSelect={handleOption}
                style={{ margin: "20px" }}
                />
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel}>Cancel</Button>
            <Button onClick={() => value ? props.onComplete(value) : props.onCancel()}>Save</Button>
        </DialogActions>
    </Dialog>)
}

export const StatusSelect = (props:{value: ItemStatus | undefined, setValue:(v:ItemStatus|undefined)=>void })=>{
    const {value, setValue} = props
    return (
        <LabeledSelect
            fullWidth
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value}
            label="Status"
            onChange={e => setValue(e.target.value as ItemStatus)}
        >
            <MenuItem value={ItemStatus.todo}>Todo</MenuItem>
            <MenuItem value={ItemStatus.in_progress}>In-Progress</MenuItem>
            <MenuItem value={ItemStatus.done}>Done</MenuItem>
        </LabeledSelect>)
}

export const StatusDialog = (props: { value: ItemStatus | undefined, onComplete: (value: ItemStatus) => void, onCancel: () => void }) => {
    const [value, setValue] = React.useState(props.value)

    return (<Dialog open={true}>
        <DialogTitle>Set Status</DialogTitle>
        <DialogContent>
            <Box style={{ marginTop: "20px" }}>
                <StatusSelect value={value} setValue={setValue}/>
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel}>Cancel</Button>
            <Button onClick={() => value ? props.onComplete(value) : props.onCancel()}>Save</Button>
        </DialogActions>
    </Dialog>)
}



export const StandaloneItemView = (props: {}) => {
    const { planId, itemId } = useParams()
    const [isFetching, setFetching] = useState(true)
    const [components, setComponents] = useState<Components>()
    const [config, setConfig] = useState<PlanConfig>()
    const [overview, setOverview] = React.useState<PlanOverviewDto>()
    const [isEditing, setEditing] = useState(false)
    const [error, setError] = useState<string>()

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")

    const items = components?.items
    const item = items?.find(i => i.id == itemId)
    const navigate = useNavigate()



    const refresh = () => {
        setFetching(true)

        if(planId){

            Promise.all([
                getPlanConfig(planId).then(setConfig),
                getComponents(planId, "current").then(setComponents),
                getPlanOverview(planId).then(setOverview),
            ]).catch(e => {
                console.log("Error:::", e)
                setError(e)
            }).finally(() => setFetching(false))
        }
    }

    React.useEffect(refresh, [])

    const doEdit = () => {
        setEditing(true)
    }

    if (isFetching || !config || !overview) {
        return <CircularProgress />
    } else if (!components || !item || !planId || !itemId) {
        return <>No such item: {planId}/{itemId}</>
    } else {

        const { doDelete, handleTitle, handleRelease, handleAspectSelection, handleEstimate, handleStatus } = itemHandlers(planId!!, components, setFetching, refresh)

        const canDelete = config.components?.itemsAreEditable ? true : undefined
        const onDelete = () => {
            if (canDelete && window.confirm("Are you sure you want to delete this?")) {
                doDelete(item).then(() => {
                    navigate(`/planview/${planId}`)
                })
            }
        }

        const doEditMode = () => {
            setTitle(item.title)
            setDescription(item.description)
            setEditing(true)
        }
        const doSave = () => {
            const spec: NewItemSpec = {
                id: item.id,
                title: title,
                description: description
            }
            applyChanges(spec, planId, item)
                .then(() => {
                    setEditing(false)
                    refresh()
                })
                .catch(setError)
        }
        return (
            <>
                <PlanScreenLayout 
                    overview={overview} 
                    controls={<>
                        {!isEditing && <>
                                    <Stack direction="row-reverse" >
                                        {canDelete && <IconButton aria-label="delete" size="small" onClick={onDelete}>
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>}
                                        <IconButton aria-label="edit" size="small" onClick={doEditMode}>
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                    </Stack>
                                </>}
                    </>}
                    title={(
                        <Typography variant="h3">
                            <MarkdownContent >{
                                item.title
                            }</MarkdownContent>
                        </Typography>
                    )}
                    extraPathElements={[<Typography>{item.id}</Typography>]}
                    >
                    <Stack spacing={2}>
                        

                        <ItemView
                            planId={planId}
                            editable={true}
                            hideAccordion={true}
                            components={components}
                            item={item}
                            doEdit={config.components?.itemsAreEditable ? doEdit : undefined}
                            onTitleChange={handleTitle}
                            onReleaseChange={handleRelease}
                            onAspectChange={handleAspectSelection}
                            onEstimate={handleEstimate}
                            onStatus={handleStatus}
                            onDelete={config.components?.itemsAreEditable ? doDelete : undefined}
                        />

                        <Box style={{ margin: "20px",}}>
                            {isEditing && <>
                                <Stack spacing={4}>
                                    <TextField
                                        margin="dense"
                                        id="Title"
                                        label="Title"
                                        value={title}
                                        onChange={e => setTitle((e.target.value))}
                                        fullWidth
                                        variant="standard"
                                    />
                                    <TextField
                                        margin="dense"
                                        id="Description"
                                        label="Description"
                                        value={description}
                                        onChange={e => setDescription((e.target.value))}
                                        fullWidth
                                        multiline
                                        rows={8}
                                        variant="filled"
                                    />
                                    <Stack spacing={3} direction="row-reverse">
                                        <Button onClick={doSave} variant="contained">Save</Button>
                                        <Button onClick={() => setEditing(false)}>Cancel</Button>
                                    </Stack>
                                </Stack>
                            </>}
                            {!isEditing && <>
                                <MarkdownContent >{
                                    (item.description)
                                }</MarkdownContent>
                            </>}

                        </Box>
                    </Stack>
                </PlanScreenLayout>
            </>
        )
    }

}

interface ItemViewProps {
    planId: string,
    item: ItemComponent,
    components: Components,
    editable: boolean,
    hideAccordion?: boolean
    doEdit: ((item: ItemComponent) => void) | undefined,
    onDelete: ((item: ItemComponent) => void) | undefined,
    onTitleChange: (title: string, item: ItemComponent) => void,
    onReleaseChange: (releaseRef: ReleaseRef, item: ItemComponent) => void,
    onAspectChange: (selection: AspectOption, item: ItemComponent) => void,
    onEstimate: (estimate: number, item: ItemComponent) => void,
    onStatus: (status: ItemStatus, item: ItemComponent) => void
}

const ItemView = (props: ItemViewProps) => {
    const { planId, item, components, editable, doEdit, onDelete } = props

    const [editingAspect, setEditingAspect] = useState<EditingAspect>()
    const [showReleaseDialog, setShowReleaseDialog] = React.useState(false)
    const [showStatusDialog, setShowStatusDialog] = React.useState(false)
    const [showEstimateDialog, setShowEstimateDialog] = React.useState(false)
    const [titleDialogShowing, setTitleDialogShowing] = React.useState(false)
    const releaseAssociations = components.releaseAssociations ?? {}
    const estimate = components.estimates[item.id]
    const releaseRef = releaseAssociations[item.id]


    const aspects = components.aspects ?? []
    const aspectAssociations = (components.aspectAssociations ?? {})
    const aspectRefs = aspectAssociations[item.id] ?? []

    const hideAccordion = props.hideAccordion ? true : false
    const releaseOptions:Release[] = components.releases ?? []

    const status = findLastStatusChange(item, components)?.status
    const specialTitle = components.titles && components.titles[item.id]
    const title = specialTitle || item.title

    const formatId = (s: string): string => {
        const length = 15
        if (s.length > length) {
            return s.substring(0, length - 3) + "..."
        } else {
            return s
        }
    }

    const propertyKeys = item.data ? Object.keys(item.data) : []


    const consumeAccordionClick = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleClick: MouseEventHandler = (e) => {
        consumeAccordionClick(e)
        setShowEstimateDialog(true)
    }

    const handleStatusClick: MouseEventHandler = (e) => {
        console.log("Show status dialog")
        consumeAccordionClick(e)
        setShowStatusDialog(true)
    }

    const handleReleaseClick: MouseEventHandler = (e) => {
        consumeAccordionClick(e)
        setShowReleaseDialog(true)
    }

    const setStatus = (s: ItemStatus) => {
        setShowStatusDialog(false)
        props.onStatus(s, item)
    }

    const setEstimate = (n: number) => {
        setShowEstimateDialog(false)
        props.onEstimate(n, item)
    }

    const setTitle = (n: string) => {
        setTitleDialogShowing(false)
        props.onTitleChange(n, item)
    }

    const setRelease = (n: ReleaseRef) => {
        setShowReleaseDialog(false)
        props.onReleaseChange(n, item)
    }

    const setAspect = (n: AspectOption) => {
        setEditingAspect(undefined)
        props.onAspectChange(n, item)
    }




    type ChipVariant = 'filled' | 'outlined'
    type ChipColor = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'
    interface ChipStuff {
        variant: ChipVariant
        color: ChipColor
    }
    const chipColorForStatus = (status: ItemStatus | undefined): ChipStuff => {

        const styles: Record<ItemStatus, ChipStuff> = {
            todo: { color: 'primary', variant: "outlined" },
            done: { color: 'success', variant: "filled" },
            in_progress: { color: 'success', variant: "outlined" },
            deleted: { color: 'error', variant: 'filled' }
        }

        return status ? styles[status] : styles[ItemStatus.todo]
    }

    const handleEdit = () => {
        if (doEdit) {
            doEdit(item)
        } else {
            setTitleDialogShowing(true)
        }
    }

    const dimensions = useViewport()



    const truncate = (s: string, w: number, breaks: Record<number, number> = {}) => {
        const width = dimensions.vw

        const candidates = Object.keys(breaks).map(i => parseInt(i)).filter(i => i <= width)
        const key = candidates.length == 0 ? undefined : Math.max(...candidates)


        const n = key ? breaks[key] : w

        console.log(n, "Using break ", key, " from ", width, candidates)

        let max = n - 3;
        if (s.length > n) {
            return s.substring(0, max) + "..."
        } else {
            return s
        }
    }

    const release = releaseRef && components.releases?.find(r=>r.id == releaseRef.releaseId)

    const releaseLabelChip = (
        <Chip
            variant="outlined"
            size="small"
            label={release ? truncate(release.name, 40, { 800: 150, 1000: 200 }) : "?? release"}
            onClick={editable ? handleReleaseClick : undefined}
            color={releaseRef ? "default" : "warning"}
        />
    )

    const aspectChips = aspects.map(aspect => {
        const association = aspectRefs.find(r => r.aspectId == aspect.id)
        const chosenValue = aspect.values.find(v => v.id == association?.valueId)
        const formattedValue = chosenValue ? truncate(chosenValue?.name, 40, { 800: 150, 1000: 200 }) : "??"

        const handleAspectClick: MouseEventHandler = (e) => {
            consumeAccordionClick(e)
            setEditingAspect({
                selection: association,
                aspect: aspect,
            })
        }

        return <Chip
            variant="outlined"
            size="small"
            label={`${aspect.name}: ${formattedValue}`}
            onClick={editable ? handleAspectClick : undefined}
            color={chosenValue ? "primary" : "warning"}
        />
    })

    const header = (
        <Grid container

            spacing={2}
            style={{
                width: "100%",
                margin: "10px"
            }}>
            <Grid xs={12} md={6} lg={6} xl={4}>
                <Stack spacing={2}>
                    <Stack direction="row" spacing={1} style={{ textAlign: "right" }}>
                        <Chip
                            onClick={editable ? handleStatusClick : undefined} label={(status ?? "?? status").replaceAll("_", " ")}
                            color={!status ? "warning" : chipColorForStatus(status).color}
                            variant={chipColorForStatus(status).variant}
                            size="small" />

                        <Chip
                            onClick={editable ? handleClick : undefined}
                            label={`${estimate || "??"} estimate`}
                            color={estimate ? "info" : "warning"}
                            variant="outlined"
                            size="small" />
                    </Stack>
                    <Box>{aspectChips.map(chip => <Box style={{ display: "inline-block", marginRight: "10px" }}>{chip}</Box>)}</Box>
                    <Box>{releaseLabelChip}</Box>
                    {/* 
                        */}
                </Stack>
            </Grid>
            <Grid xs={12} md={6} lg={6} xl={8}>

                {!hideAccordion && <>
                    {/* <Link style={{color: '#111111'}} to={`/planview/${planId}/items/${item.id}`}>{"#" + formatId(props.item.id)}</Link> */}
                    <MarkdownContent >{
                        // `([${formatId(props.item.id)}](${`/planview/${planId}/items/${item.id}`}) )` + 
                        title
                    }</MarkdownContent>
                </>}

            </Grid>

        </Grid>
    )
    
    const body = (<>
        {editable && <Stack direction="row">
            <IconButton aria-label="edit" size="small" onClick={handleEdit}>
                <EditIcon fontSize="inherit" />
            </IconButton>
            {onDelete && <IconButton aria-label="delete" size="small" onClick={() => onDelete(item)}>
                <DeleteIcon fontSize="inherit" />
            </IconButton>}
        </Stack>}
        <hr />
        {/* <Button  onClick={()=>onDelete(item)}>remove</Button> */}
        <Typography>
            <Typography variant="h4"></Typography>
            <Typography variant="h5">Description</Typography>
            <MarkdownContent >{
                props.item.description
            }</MarkdownContent>
            {(propertyKeys.length > 0) && <>
                <Typography variant="h5">Additional Properties</Typography>
                <Box>
                    {propertyKeys.map((key) => {
                        return <div>{key}: {linkify(item.data[key] ?? "")}</div>
                    })}
                </Box>
            </>}
        </Typography>
    </>
    )

    return (<>
        {editingAspect && <AspectLabelDialog value={editingAspect?.selection} aspect={editingAspect.aspect} onComplete={setAspect} onCancel={() => setEditingAspect(undefined)} />}
        {showReleaseDialog && <ReleaseDialog value={releaseRef} options={releaseOptions} onComplete={setRelease} onCancel={() => setShowReleaseDialog(false)} />}
        {titleDialogShowing && <TitleDialog value={title} onComplete={setTitle} onCancel={() => setTitleDialogShowing(false)} />}
        {showEstimateDialog && <EstimateDialog value={estimate} onComplete={setEstimate} onCancel={() => setShowEstimateDialog(false)} />}
        {showStatusDialog && <StatusDialog value={status} onComplete={setStatus} onCancel={() => setShowStatusDialog(false)} />}
        {hideAccordion && <Stack spacing={3} style={{ textAlign: "left" }}>
            {header}
        </Stack>}
        {!hideAccordion &&
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {header}
                    <Link style={{ position: "absolute", right: "20px", color: '#aaa', fontSize: "9pt", textAlign: "right", display: "inline-block", minWidth: "200px" }} to={`/planview/${planId}/items/${item.id}`}>{"#" + formatId(props.item.id)}</Link>
                </AccordionSummary>
                <AccordionDetails>
                    {body}
                </AccordionDetails>
            </Accordion>
        }
    </>)
}



export const handleAspectSelection = (selection: AspectOption, item: ItemComponent, components:Components, planId:string):Promise<any> => {
    console.log("Make this stick: ", selection)
    const aspectId = selection.aspect.id
    const saveValue = (v: AspectValue):Promise<any> => {
        const aspectAssociations = (components.aspectAssociations ?? {})
        const aspectRefs = aspectAssociations[item.id] ?? []
        const newRef: AspectRef = {
            aspectId: aspectId,
            valueId: v.id,
        }
        const updatedAssociations = aspectRefs.filter(r => r.aspectId != aspectId).concat([newRef])


        return patchAspectAssignments(planId, { [item.id]: updatedAssociations })
    }

    if (!selection.existing) {
        const v: AspectValue = {
            id: "",
            name: selection.label,
        }
        return addAspectValue(planId, selection.aspect.id, v)
            .then(saveValue)
    } else {
        return saveValue(selection.existing)
    }
}

interface EditingAspect {
    selection: AspectRef | undefined
    aspect: Aspect
}


const itemHandlers = (planId: string, components: Components | undefined, setFetching: (isFetching: boolean) => void, doneFetchingNeedsRefresh: () => void) => {

    const doDelete = (item: ItemComponent): Promise<string> => {
        console.log("Deleting", item)

        return new Promise((resolveSuccess, resolveFailed) => {

            if (planId) {
                sendItemsPatch(
                    planId,
                    {
                        additions: [],
                        updates: [],
                        deletions: [item.id]
                    },
                    () => resolveSuccess("yay"),
                    resolveFailed
                )
            } else {
                resolveFailed("Can't delete this")
            }
        })

    }



    return {
        doDelete,
        handleTitle: (title: string, item: ItemComponent) => {
            const p: Record<string, string> = {}
            p[item.id] = title

            setFetching(true)
            setTitles(planId, p)
                .then(doneFetchingNeedsRefresh)
                .catch(console.log)
        },
        handleRelease: (releaseRef: ReleaseRef, item: ItemComponent) => {

            if (components) {
                const updatedEstimates = {
                    [item.id]: releaseRef
                }
                setFetching(true)
                setReleases(planId, updatedEstimates)
                    .then(doneFetchingNeedsRefresh)
                    .catch(console.log)
            }
        },

        handleAspectSelection: (selection: AspectOption, item: ItemComponent) => {
            console.log("Make this stick: ", selection)

            if (components) {
                setFetching(true)
                handleAspectSelection(selection, item, components, planId)
                    .then(doneFetchingNeedsRefresh)
                    .catch(console.log)
            }
        },

        handleEstimate: (estimate: number, item: ItemComponent) => {

            if (components) {
                setFetching(true)
                setEstimate(planId, item.id, estimate, components)
                    .then(doneFetchingNeedsRefresh)
                    .catch(console.log)
            }
        },


        handleStatus: (status: ItemStatus, item: ItemComponent) => {

            if (components) {
                updateItemStatus(status, item, planId)
                    .then(doneFetchingNeedsRefresh)
                    .catch(console.log)
            }
        },


    }
}


export const joinReact = (items: ReactNode[], separator: ReactNode): ReactNode => {
    return (<>
        {items.map((i, idx) => <>
            {(idx > 0) && separator}{i}
        </>)}
    </>)
}
export const isDone = (i: ItemComponent, components: Components): boolean => {
    const status = components && findLastStatusChange(i, components)?.status
    return status?.toString() === ItemStatus.done.toString()
}

export const isDeleted = (i: ItemComponent, components: Components): boolean => {
    const status = components && findLastStatusChange(i, components)?.status
    return status?.toString() === ItemStatus.deleted.toString()
}

export const isFinished = (i: ItemComponent, components: Components): boolean => isDone(i, components) || isDeleted(i, components)


export const isFinishedStatus = (i: StatusChange): boolean => {

    return i.status?.toString() === ItemStatus.done.toString() || i.status?.toString() === ItemStatus.deleted.toString()
}


export default () => {
    const { planId } = useParams()
    const [isFetching, setFetching] = React.useState(true)
    const [showCompleted, setShowCompleted] = React.useState(false)
    const [versionId, setVersionId] = useState("current")
    const [components, setComponents] = React.useState<Components>()
    const [plan, setPlan] = React.useState<PlanOverviewDto>()
    const [overview, setOverview] = React.useState<PlanOverviewDto>()
    const [config, setConfig] = React.useState<PlanConfig>()
    const [error, setError] = React.useState<string>()
    const [editingItem, setEditingItem] = React.useState<ItemComponent>()
    const [closedAfter, setClosedAfter] = React.useState<Moment>()
    const [rawVersions, setVersions] = React.useState<VersionListEntry[]>()
    const [showFilters, setShowFilters] = useState(false)


    const isEditable = (versionId == "current") && (config?.components?.itemsAreEditable == true)

    const pretendCurrentVersion: VersionListEntry = {
        id: "current",
        whenChanged: 0,
        userId: undefined
    }
    const versions: VersionListEntry[] = [pretendCurrentVersion].concat((rawVersions ?? []).sort((a, b) => b.whenChanged - a.whenChanged))

    const items = components?.items

    const refresh = () => {
        setFetching(true)

        if (planId) {
            Promise.all([
                getVersions(planId).then(setVersions),
                getActualPlan(planId).then(setPlan),
                getPlanConfig(planId).then(setConfig),
                getPlanOverview(planId).then(setOverview),
                getComponents(planId, versionId).then(setComponents),
            ]).catch(e => {
                console.log("Error:::", e)
                setError(e)
            }).finally(() => setFetching(false))
        }
    }

    React.useEffect(refresh, [planId, versionId])

    const doneFetchingNeedsRefresh = () => {
        setFetching(false)
        refresh()
    }

    const foo = (i: ItemComponent): boolean => {
        if (!closedAfter) {
            return true
        } else {
            const statusChange = components && findLastStatusChange(i, components)

            if (!statusChange) {
                return false
            } else {
                const isClosed = statusChange.status?.toString() === ItemStatus.done.toString()
                if (!isClosed) {
                    return false
                } else {
                    return moment(statusChange.whenChanged).isAfter(closedAfter)
                }
            }
        }
    }

    const visibleItems = items && items.filter(i => !isDeleted(i, components)).filter(i => showCompleted || !isDone(i, components)).filter(foo)

    const beforeFirst = (): ItemComponent | undefined => {

        const firstVisible = visibleItems && visibleItems[0]
        const firstVisibleIdx = items?.findIndex(i => i.id == firstVisible?.id)

        const beforeFirst = (items && firstVisibleIdx && firstVisibleIdx > 0) ? items[firstVisibleIdx - 1] : undefined
        return beforeFirst
    }

    const insertAfter = (item: ItemComponent, after: ItemComponent | undefined) => {
        const previous = after || beforeFirst()
        console.log(item.id, "goes after", after?.id, "/", previous?.id)
        setItemOrder({ id: item.id, after: previous?.id }, planId!!)
            .then(refresh)
    }

    const hideEditor = () => {
        setEditingItem(undefined)
    }


    const respondToUpdates = () => {
        hideEditor()
        refresh()
    }
    const doEdit = (item: ItemComponent) => {
        console.log("Editing", item)
        setEditingItem(item)
    }

    if (error) {
        return <>
            <Box style={{ textAlign: "center" }}>
                <Alert style={{ display: "inline-block" }} color="error">{error}</Alert>
            </Box>
        </>
    } if (!(overview && config)) {
        return <CircularProgress />
    } else {
        console.log("Config is ", config)
        const { doDelete, handleTitle, handleRelease, handleAspectSelection, handleEstimate, handleStatus } = itemHandlers(planId!!, components, setFetching, doneFetchingNeedsRefresh)


        const deleteOne = (item: ItemComponent) => {
            doDelete(item).then(() => {
                hideEditor()
                refresh()
            })
        }
        const resetFilters = () => {
            setShowCompleted(false)
            setClosedAfter(undefined)
        }
        const toggleFilterDisplay = () => setShowFilters(!showFilters)
        const isRemote = (!config?.components && !config.components?.itemsAreEditable)

        const filterDisplay = (!showFilters && showCompleted) && <Box style={{ display: "inline-block" }}><Alert onClose={resetFilters} color="warning">{(closedAfter ? `Filtering to items closed after ${closedAfter}` : 'showing completed items')}</Alert></Box>

        return (<PlanScreenLayout 
                overview={overview} 
                controls={<></>}
                actionsBar={(<PlanControlsBand 
                    planId={planId!!}
                    versionId={versionId} 
                    isEditable={isEditable} 
                    plan={plan} 
                    config={config} 
                    components={components} 
                    respondToUpdates={respondToUpdates}/>)}>
            <PlanSubmenu planId={planId!!} components={components}/>
            <Stack style={{ margin: "20px" }}>
            
            <Stack direction="row-reverse">
                <Stack direction="row" spacing={5} style={{ display: "inline-block", marginRight: "20px" }}>

                    {filterDisplay}
                    {showFilters && <>
                        <Box style={{ display: "inline-block", border: "1px solid grey", borderRadius: "5px", margin: "15px", padding: "5px" }}>

                            <Stack spacing={3} >
                                <Box>
                                    <IconButton onClick={toggleFilterDisplay}><CloseIcon /></IconButton>
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        value="start"
                                        label="Hide Completed"
                                        labelPlacement="start"
                                        control={<Switch
                                            checked={!showCompleted}
                                            onChange={e => setShowCompleted(!e.target.checked)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                    />
                                </Box>
                                <Box>
                                    <DateTimePicker label="Closed After" value={closedAfter} onChange={v => setClosedAfter(v ?? undefined)} />
                                </Box>
                            </Stack>
                        </Box>
                    </>}

                    {(!showFilters && !filterDisplay) ? <IconButton onClick={toggleFilterDisplay}><FilterAltIcon /></IconButton> : undefined}
                    {versions && <LabeledSelect
                        labelId="iteration-boundaries-select"
                        label="Version"
                        style={{ minWidth: "150px" }}
                        fullWidth={false}
                        value={versionId} onChange={e => setVersionId(e.target.value)}>
                        {versions.map(v => {
                            const w = moment(v.whenChanged)
                            return (<MenuItem value={v.id}>{v.id == "current" ? v.id : `${w.fromNow().toString()} - ${w.toString()}`}</MenuItem>)
                        })}
                    </LabeledSelect>}
                </Stack>
            </Stack>
            {(visibleItems && visibleItems.length == 0) ? "This plan is empty" : ""}
            {
                !visibleItems ? <CircularProgress /> : <ReorderableList
                    useThumbs={true}
                    items={visibleItems}
                    editable={isEditable}
                    insertAfter={insertAfter}
                    renderItem={x =>
                        <ItemView
                            planId={planId!!}
                            editable={isEditable}
                            item={x}
                            components={components}
                            doEdit={config.components?.itemsAreEditable ? doEdit : undefined}
                            onTitleChange={handleTitle}
                            onReleaseChange={handleRelease}
                            onAspectChange={handleAspectSelection}
                            onEstimate={handleEstimate}
                            onStatus={handleStatus}
                            onDelete={config.components?.itemsAreEditable ? deleteOne : undefined} />} />
            }
            {(editingItem && planId && components) && <ItemEditorDialog components={components} planId={planId} existing={editingItem} config={config} onComplete={respondToUpdates} onCancel={hideEditor} />}
        </Stack>
        </PlanScreenLayout>)

    }
}