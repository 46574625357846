import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import BacklogView, {StandaloneItemView} from './BacklogView';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import LoggedInOrRedirect from './LoggedInOrRedirect';
import { GroupView, GroupsView } from './PlansView';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Map from './Map';
import TeamBoard from './TeamBoard';
import NavigateWithParams from './NavigateWithParams';
import PlanReleasesPage from './PlanReleasesPage';
import PlanReleasePage from './PlanReleasePage';
import TimeWindow, { TimeWindowAdmin } from './TimeWindow';

export const makeOrganizationUrl = (sourceId:string)=>`/groups/${sourceId}`
export const makeWorkflowUrl = (planId:string)=> `/planview/${planId}/workflow`
export const makePlanUrl = (planId:string) => makeWorkflowUrl(planId)
export const makePrioritizationUrl = (planId:string)=> `/planview/${planId}/prioritization`
export const makeReleasesUrl = (planId:string) => `/planview/${planId}/releases`
export const makeReleaseUrl = (planId:string, releaseId:string) => `/planview/${planId}/releases/${releaseId}`
export const makeReleaseBreakdownUrl = (planId:string, aspectId:string) => `/planview/${planId}/map/${aspectId}`


export const WithPlanRenames = (props:{children:ReactNode})=>{
  const {children} = props
  const renames:Record<string, string> = {
    "caruso-stu":"caruso-engineering"
  }

  const {planId} = useParams()
  const navigate = useNavigate()
  
  const path = window.location.pathname
  const rename = planId && renames[planId]


  useEffect(()=>{
    if(rename){
      const oldRoot = `/planView/${planId}`
      const newRoot = `/planView/${rename}`
    
      const newUrl = `${newRoot}${path.substring(oldRoot.length)}`
      console.log("Need to go to ", newUrl)
      navigate(newUrl)
    }
  }, [rename])

  if(rename){
    return <>...</>
  }else{
    return <>
    {children}
  </>
  }
}

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BrowserRouter>
        <WithPlanRenames>
        <Routes>
          <Route path="/" element={<LoggedInOrRedirect makeChildren={userId=><GroupsView userId={userId}/>}/>} />

          <Route path="/timewindow/:windowId" element={<TimeWindow />} />
          <Route path="/timewindow/:windowId/admin" element={<LoggedInOrRedirect makeChildren={userId=><TimeWindowAdmin />}/>} />
          <Route path="/planview/:planId/*" element={<WithPlanRenames>
              <Routes>
                <Route index element={<NavigateWithParams to={({planId})=>makeWorkflowUrl(planId!!)}  />} />
                <Route path="items/:itemId" element={<LoggedInOrRedirect><StandaloneItemView /></LoggedInOrRedirect>} />
                <Route path="map/:aspectId" element={<LoggedInOrRedirect><Map /></LoggedInOrRedirect>} />
                <Route path="teamboard" element={<NavigateWithParams to={({planId})=>makeWorkflowUrl(planId!!)}  />} />
                <Route path="workflow" element={<LoggedInOrRedirect><TeamBoard /></LoggedInOrRedirect>} />
                <Route path="prioritization" element={<LoggedInOrRedirect><BacklogView /></LoggedInOrRedirect>} />
                <Route path="releases/:releaseId" element={<LoggedInOrRedirect><PlanReleasePage /></LoggedInOrRedirect>} />
                <Route path="releases" element={<LoggedInOrRedirect><PlanReleasesPage /></LoggedInOrRedirect>} />
              </Routes>
          </WithPlanRenames>} />
          <Route path="/groups/:groupId" element={<LoggedInOrRedirect makeChildren={userId=><GroupView userId={userId}/>}/>} />          
        </Routes>
        </WithPlanRenames>
      </BrowserRouter>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
