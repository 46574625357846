import { useEffect, useState } from "react"

export const useWebsocket = <V,>(url:string|undefined, initialValue:V, onMessage:(event: MessageEvent<any>, v:V)=>V):[boolean, V]=>{

    // console.log("[ws] hook")
  
    const [state, setState] = useState<V>(initialValue)
    const [reconnectBump, setReconectBump] = useState<any>()

  
    useEffect(() => {
      let s:WebSocket|undefined = undefined
      if(url){
        
        var fastState = state
        console.log("SSS [ws] Opening Socket", url);
        const socket = new WebSocket(url);
        socket.addEventListener("open", (event) => {
            console.log("[ws] Socket opened ", event);
            socket.send("Hello Server!");
        });

        socket.addEventListener("message", e=>{
            // console.log("[ws] got something")
            fastState = onMessage(e, fastState)
            setState(fastState)
        });
        
        const triggerSocketError = (reason:string)=>{
            console.log("SSS socket exited")
            setTimeout(function() {
                // setSocket(undefined)
                // do something to trigger a reconnect here
                setReconectBump(new Date().getTime())
            }, 1000);
        }

        socket.addEventListener("close", ()=>triggerSocketError("close at"));

        socket.addEventListener("error", ()=>triggerSocketError("error"));

        console.log("SSS Opened socket ", url, socket);
        s = socket
      }

      return ()=>{
        if(s){
            console.log("SSS Shutting down the socket at", url, s)
            s.close()
        }
      }
    }, [url, reconnectBump])
  
    return [true, state]
  }
  